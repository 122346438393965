<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="block block--sample" :class="{'abstract': isAbstract}">
          <div class="block block--sample-child">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-5 mb-4 mb-md-0">
                <div class="wrapper d-flex flex-column justify-content-center">
                  <p v-if="text[0] !== ''" class="text fs-16 fs-md-20 fw-500 mb-3 mb-md-4 ta-center ta-md-left" :class="'tc-' + theme">{{ text[0] }}</p>
                  <h1 class="title fs-32 fs-xl-42 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4">{{ title }}</h1>
                  <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 ta-center ta-md-left">{{ text[1] }}</p>
                  <template v-if="btn">
                    <a :href="btn.to" v-smooth-scroll="{ duration: 1000, offset: -100 }" class="link link--arrow link--td-none mt-4 mx-auto mx-md-0">
                      <span class="link fs-16 fs-md-18 fs-xl-20 tc-blue">{{ btn.text }}</span>
                      <i class="ico ico--ui-arrow">
                        <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow">
                      </i>
                    </a>
                  </template>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-7">
                <div class="img img--sample">
                  <img :src="img" alt="sample">
                  <img :src="imgmob" alt="sample">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Sample',
  props: ['title', 'text', 'img', 'imgmob', 'isAbstract', 'theme', 'btn']
}
</script>
