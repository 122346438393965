<template>
  <div class="page page--partners">
    <section class="section" id="sec-1" data-sec="1">
      <comp-sample :title="cc.sec1.title" :text="cc.sec1.text" :img="'/img/pages/partners/sec1/img-1.png'" :imgmob="'/img/pages/partners/sec1/mob/img-1.png'" :isAbstract="true" :theme="'green'"/>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <comp-start/>
    </section>
    <section class="section section--gray" id="sec-3" data-sec="3">
      <div class="container">
        <div class="row flex-column-reverse flex-xl-row">
          <div class="col-12 col-xl-7">
            <div class="row pe-xl-5">
              <div class="col-12 col-md-6 mb-4" v-for="(item, i) in cc.sec3.boxes" :key="'benefit-' + i">
                <div class="block block--benefits">
                  <div class="ico ico--partners mb-3 mb-lg-4" :class="'ico--partners-' + item.theme">
                    <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
                  </div>
                  <h2 class="title fs-18 fs-md-20 fw-600 mb-2 mb-lg-3">{{ item.title }}</h2>
                  <p class="text tc-silver fs-14 fs-sm-16 lh-20">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-5">
            <div class="wrapper d-flex flex-column justify-content-center">
              <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec3.title }}</h2>
              <p class="text tc-blue fs-14 fs-sm-20 fw-600 lh-20 lh-md-17 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec3.text[0] }}</p>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-4 mb-md-5">{{ cc.sec3.text[1] }}</p>
              <a :href="$t('external.reg.0') + $i18n.locale + $t('external.reg.1')" class="btn btn-3-blue btn-w-220 btn-h-66 d-none d-xl-flex"><span>{{ cc.sec3.btn.text }}</span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompSample from '@/components/Sample'
import CompStart from '@/components/Start'
export default {
  name: 'Partners',
  components: {
    CompSample, CompStart
  },
  computed: {
    cc () {
      return this.$t('pages.partners')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.partners')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
