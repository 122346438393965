<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="block block--start">
          <div class="row">
            <div class="col-12 col-lg-8 col-xl-8">
              <h2 class="title tc-white fs-26 fs-sm-24 fs-md-28 fs-xl-32 fw-600 lh-15 pe-xl-1 mb-5 pb-4 mb-md-4 mb-lg-0 pb-md-0">{{ cc.title }}</h2>
            </div>
            <div class="col-12 col-lg-3 col-xl-3 offset-lg-1">
              <div class="wrapper d-flex align-items-center justify-content-center justify-content-lg-end">
                <src-external to="reg" class="btn btn-3-blue btn-w-240 btn-h-66">
                  <span>{{ cc.btn }}</span>
                </src-external>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SrcExternal from '@/components/SrcExternal'
export default {
  components: {
    SrcExternal
  },
  computed: {
    cc () {
      return this.$t('components.start')
    },
    locale () {
      let res = this.$i18n.locale
      res = (res === 'en' || res === 'es') ? '/' : '/' + res + '/'
      return res
    }
  }
}
</script>
